<template lang="pug">
  div
    el-alert(
      title="Bu bölümü görüntüleme yetkiniz yok"
      v-if="!can('suppliers.list')"
      :closable="false"
      type="error"
      show-icon
      center
    )
    el-card(body-style="padding:0")
      div(slot="header")
        el-form(inline)
          el-form-item
            el-button(
              type="primary"
              @click="create"
              icon="el-icon-plus"
              :disabled="!can('suppliers.create')"
            ) Oluştur
      el-input(placeholder="Tedarikçi ara" v-model="search" v-if="can('suppliers.list')")
        i.el-input__icon.el-icon-search(slot="prefix")
      el-table(
        :data="filteredsuppliers"
        style="width: 100%"
        v-loading="fetching"
        @row-dblclick="edit"
        :height="$store.state.windowHeight-192"
        size="mini"
        v-if="can('suppliers.list')"
      )
        el-table-column(type="index" width="30px" class-name="text-muted" align="right")
        el-table-column(prop="name" label="Tedarikçi" sortable)
        el-table-column(prop="phone" label="Telefon" sortable)
        el-table-column(prop="email" label="Email" sortable)
        el-table-column(prop="address" label="Adres" sortable)
        el-table-column(prop="id" label="İşlem" align="center")
          template(v-slot="props")
            el-button-group
              el-button.micro(plain @click="edit(props.row)"): span.el-icon-edit
              el-button.micro(plain @click="remove(props.row.id)"): span.el-icon-delete.text-danger
    el-drawer(:visible.sync="modal" size="450px")
      span.text-center(slot="title") {{ supplier.name }}
      el-form.pt-20.pr-20.pl-20(label-position="right" label-width="130px")
        el-form-item(label="Tedarikçi")
          el-input(v-model="supplier.name")
        el-form-item(label="Telefon")
          el-input(v-model="supplier.phone")
        el-form-item(label="E-mail")
          el-input(v-model="supplier.email")
        el-form-item(label="Adres")
          el-input(v-model="supplier.address")
      center.pt-20: el-button(@click="save" :loading="saving" icon="el-icon-check") Kaydet
</template>
<script>
import Company from '@/models/Company'
export default {
  name: 'suppliers',
  data(){
    return {
      fetching: false,
      saving: false,
      modal: false,
      search: '',
      supplier: new Company({ name: '', type: 'supplier', phone: '', email: '', address: '' }),
      suppliers: []
    }
  },
  created(){
    if(this.can('suppliers.list')) {
      this.fetch()
    }
  },
  methods: {
    async fetch(){
      try{
        this.fetching = true
        this.suppliers = await Company.where('type', 'supplier').get()
        this.fetching = false
      }catch({ response }){
        this.$message.error(response.data.message)
        this.fetching = false
      }
    },
    create(){
      this.supplier = new Company({ name: '', type: 'supplier', phone: '', email: '', address: '' })
      this.modal = true
    },
    async edit({ id }){
      this.supplier = await Company.find(id)
      this.modal = true
    },
    async save(){
      try{
        this.saving = true
        await this.supplier.save()
        this.saving = false
        this.modal = false
        this.fetch()
      }catch(e){
        this.saving = false
        this.$message.error(e.message)
      }
    },
    async remove(id){
      await this.$confirm('Kayıt silinmesine onay veriniz', 'Uyarı', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Vazgeç',
        type: 'warning',
        center: true
      })
      try{
        let { data } = await window.axios.delete('/companies/' + id)
        this.fetch(false)
        if(data.deleted){
          this.$message({
            type: 'success',
            message: 'Kayıt silinmiştir',
            center: true,
            closable: true
          })
        }
      }catch(e){
        this.$message({
          type: 'error',
          message: 'Üzerine ödeme veya ürünler tanımlanmıştır. Kayıt silinemez',
          center: true,
          closable: false
        })
      }
    },
  },
  computed: {
    filteredsuppliers: function(){
      return this.suppliers.filter(a => a.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  }
}
</script>